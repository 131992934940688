import React, { useEffect } from 'react';
import loadable from "@loadable/component";

import { cn, setBodyOverflow } from '../../utils';
import * as styles from './PopupForm.module.scss';
import IPopupForm from './PopupForm.type';

const Form = loadable(() => import('../Form/Form'));

const PopupForm = (props: IPopupForm) => {
    useEffect(() => {
        setBodyOverflow(props.showPopup ? "hidden" : "");
    }, [props.showPopup]);
    return (
        <div
            className={cn(styles.element, props.showPopup && styles.show)}
            onClick={props.closePopupFn}
        >
            <Form className={styles.form} {...props} />
        </div>
    );
};

export default PopupForm;
