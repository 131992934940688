import { Link } from "gatsby";
import React, { useMemo, useState } from "react";

import Button from "../../components/Button/Button";
import Image from "../../components/Image/Image";
import Pattern from "../../components/Pattern/Pattern";
import PopupForm from "../../components/PopupForm/PopupForm";
import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import Heading from "../../components/Typography/Heading/Heading";
import { cn } from "../../utils";
import * as styles from "./PortfoliosArchive.module.scss";
import IPortfoliosArchive from "./PortfoliosArchive.type";

const PortfoliosArchive = (props: IPortfoliosArchive) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const getStarted = useMemo(
        () =>
            props.mainMenu.find((item) => item.menuOptions.isGetStarted) || {
                label: "Get Started",
                url: "/get-started",
            },
        []
    );

    return (
        <section>
            <div className={cn("container2", styles.container)}>
                <TitleUnderlineParagraph
                    heading={props.pageTitle}
                    headingLevel="h1"
                    underline="center"
                    className={styles.title}
                />
                <div className={styles.portfolios}>
                    <div className={styles.oldPortfolios}>
                        {props.children.map((portfolio) => (
                            <div
                                key={portfolio.title}
                                className={styles.oldPortfolio}
                            >
                                <span className={styles.closed}>
                                    {`Closed - ${portfolio.sections.sections[0].closed}`}
                                </span>
                                <TitleUnderlineParagraph
                                    heading={portfolio.title}
                                    headingLevel="h2"
                                    text={
                                        portfolio.sections.sections[0]
                                            .shortDescription
                                    }
                                    textSize="small"
                                    underline="left"
                                />
                                <Link
                                    to={portfolio.uri}
                                    className={styles.link}
                                >
                                    Investments
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className={styles.incoming}>
                        <Heading level="h2" className={styles.heading}>
                            {props.newInvestment.heading}
                        </Heading>

                        <div className={styles.investmentLogo}>
                            <div className={styles.inner}>
                                <div className={styles.bg}>
                                    <Image
                                        {...props.newInvestment.backgroundImage}
                                        className={styles.bgImage}
                                    />
                                    <Image
                                        {...props.pageLogo}
                                        className={styles.pageLogo}
                                    />

                                    <div className={styles.investmentNumber}>
                                        {props.newInvestment.investmentNumber}
                                    </div>
                                </div>
                                <Pattern className={styles.topPattern} />
                                <Pattern className={styles.bottomPattern} />
                            </div>
                            <Button
                                href={getStarted.url}
                                type="large"
                                className={cn(styles.button, styles.ctaButton)}
                            >
                                {getStarted.label}
                            </Button>
                            {/*<ButtonWithIcon*/}
                            {/*    {...props.newInvestment.ctaButton}*/}
                            {/*    onClick={() => {*/}
                            {/*        setShowPopup(true);*/}
                            {/*    }}*/}
                            {/*    className={styles.ctaButton}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>
            <PopupForm
                fields={props.wpFields}
                submitButtonText={props.submitButtonText}
                showPopup={showPopup}
                closePopupFn={() => {
                    setShowPopup(false);
                }}
                formTitle={props.newInvestment.ctaButton.text}
                caption={props.caption}
                defaultHubspotListId={props.defaultHubspotListId}
                emailStructure={props.emailStructure}
                defaultTargetEmail={props.defaultTargetEmail}
                defaultSubmitAction={props.defaultSubmitAction}
            />
        </section>
    );
};

export default PortfoliosArchive;
