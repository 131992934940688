// extracted by mini-css-extract-plugin
export var bg = "PortfoliosArchive-module--bg--766c0";
export var bgImage = "PortfoliosArchive-module--bg-image--e3681";
export var bottomPattern = "PortfoliosArchive-module--bottom-pattern--adb41";
export var button = "PortfoliosArchive-module--button--7c6d5";
export var closed = "PortfoliosArchive-module--closed--9e030";
export var container = "PortfoliosArchive-module--container--01d56";
export var ctaButton = "PortfoliosArchive-module--cta-button--37071";
export var heading = "PortfoliosArchive-module--heading--1f4b2";
export var incoming = "PortfoliosArchive-module--incoming--08eb4";
export var inner = "PortfoliosArchive-module--inner--22185";
export var investmentLogo = "PortfoliosArchive-module--investment-logo--4c1d0";
export var investmentNumber = "PortfoliosArchive-module--investment-number--1842f";
export var link = "PortfoliosArchive-module--link--4c147";
export var oldPortfolio = "PortfoliosArchive-module--old-portfolio--41efe";
export var oldPortfolios = "PortfoliosArchive-module--old-portfolios--572be";
export var pageLogo = "PortfoliosArchive-module--page-logo--b874b";
export var portfolios = "PortfoliosArchive-module--portfolios--7021f";
export var title = "PortfoliosArchive-module--title--36803";
export var topPattern = "PortfoliosArchive-module--top-pattern--3b204";